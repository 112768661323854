import React, { useState } from 'react';
import styles from './index.module.sass';

//Components
import Banner from './Banner';
import Stripe from '../Stripe';
import BannerText from '../BannerText';
import ThreeBanners from '../ThreeBanners';
import BlankSpace from '../BlankSpace';
import CategoriesCarousel from '../CategoriesCarousel';
import HeroWithVideo from '../HeroWithVideo';
import BannerSide from '../BannerSide';
import CountDown from '../CountDown';
import AdventCalendar from '../AdventCalendar';

const Home = (props) => {
  const { device = {}, history } = props;
  const { screenSize = 'desktop' } = device;
  const [showVideo, setShowVideo] = useState(true);

  // For count down (uncomment to use)
  // const target_date = new Date('November 19, 2024 23:59:59').getTime();
  // const initial_date = new Date('November 13, 2024 01:00:00').getTime();
  // const today = new Date().getTime();
  // const [showCount, setShowCount] = useState(target_date > today && today > initial_date);

  // For advent calendar (uncomment to use)
  const [adventActive] = useState(true);

  function closeVideo() {
    setShowVideo(false);
  }

  return (
    <div id={styles.Home} className={styles[screenSize]}>
      {showVideo ? (
        <div className={styles.videoContainer}>
          <div className={styles.overlay} onClick={() => closeVideo()}></div>
          <div className={styles.closeVideo} onClick={() => closeVideo()}>
            X
          </div>
          <div
            className={`${adventActive ? styles.adventCard : styles.videoCard} ${
              styles[screenSize]
            }`}>
            <AdventCalendar device={device} />
            {/* {(showCount && (
              <CountDown device={device} setShowCount={setShowCount} target_date={target_date} />
            )) || (
              <iframe
                width='95%'
                height='95%'
                src='https://www.youtube.com/embed/Erbej6ZS3ps?autoplay=1&mute=1'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen='allowfullscreen'></iframe>
            )} */}
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <Banner device={device} />
      <Stripe device={device} margin='-5px 0px 0px 0px' />

      <BannerText text='BIENVENIDA A TERRAMAR' />

      <ThreeBanners
        screenSize={screenSize}
        urlOne='https://webimages.terramarbrands.com.mx/webpage/home/strenght-system.jpg'
        oneTitle='CELULAR STRENGHT SYSTEM'
        oneText='Microesferas de hidratación prolongada para el día. Células madre de manzana que actúan durante la noche.'
        onceCallback={() => {
          window.scrollTo(0, 0);
          history.push('/products/product/17001');
        }}
        urlTop='https://webimages.terramarbrands.com.mx/usawebpage/home/notes-extiques-desktop.jpg'
        topMobile='https://webimages.terramarbrands.com.mx/usawebpage/home/notes-exotiques-mobile.jpg'
        topVideo='https://www.youtube.com/embed/2R6_MI6rfRc?autoplay=0&mute=0&rel=0&playlist=2R6_MI6rfRc'
        topTitle='NOTES EXOTIQUES'
        topText='La colección Notes Exotiques fue creada para TERRAMAR por dos importantes casas perfumistas en sede en Europa.'
        topCallback={() => {
          window.scrollTo(0, 0);
          history.push('/products/product/34017');
        }}
        urlBottom='https://webimages.terramarbrands.com.mx/webpage/home/productos.jpg'
        bottomTitle='CATÁLOGOS'
        bottomText='Descubre los nuevos productos de belleza, precios y ofertas en nuestros folletos y catálogos.'
        bottomCallback={() => {
          window.scrollTo(0, 0);
          history.push('/catalog');
        }}
      />

      <BlankSpace height={24} />

      <CategoriesCarousel screenSize={screenSize} history={history} />

      <BlankSpace height={24} />

      <HeroWithVideo
        screenSize={screenSize}
        videoURL='https://webimages.terramarbrands.com.mx/usawebpage/videoplayer/corporativousa.MP4'
        titleText='ÚNETE A TERRAMAR BRANDS'
        subtitleText='y alcanza tus sueños'
        buttonText='!Únete ahora!'
        youtubeVideo='https://www.youtube.com/embed/o8zHxanAJ-A?autoplay=0&mute=0&rel=0&playlist=o8zHxanAJ-A'
        buttonCallback={() => {
          window.scrollTo(0, 0);
          history.push('/joinus');
        }}
        videoThumb='https://webimages.terramarbrands.com.mx/usawebpage/home/videothumb.png'
      />

      <BlankSpace height={24} />

      <BannerSide
        screenSize={screenSize}
        urlImage='https://webimages.terramarbrands.com.mx/usawebpage/home/nuestra-historia.jpg'
        title='NUESTRA HISTORIA'
        subtitle1='Absoluto compromiso de brindar alegría al'
        subtitle2='MAYOR NÚMERO DE PERSONAS POSIBLES'
        text='Fundada en México hace más de 15 años, Terramar Brands te brinda la oportunidad de crear un negocio propio para crecer personal y económicamente. Con el mejor Programa de Linaje de Multinivel, Terramar ha logrado transformar la vida de miles de personas y sus familias en todo el País.'
        text2='En 2022 abrimos esta gran oportunidad al mercado estadounidense, con la intención de lograr que más personas cumplan sus sueños y metas siendo parte de nuestra Gran Familia. Hemos cambiado la historia de miles de familias. ¡Únete a ser parte de ella!'
        seeMore='Leer más'
        textInBottom={false}
        buttonCallback={() => {
          window.scrollTo(0, 0);
          history.push('/about');
        }}
      />

      <BlankSpace height={24} />

      <BannerSide
        screenSize={screenSize}
        urlImage='https://webimages.terramarbrands.com.mx/usawebpage/home/fundacion-desktop.jpg'
        urlMobile='https://webimages.terramarbrands.com.mx/usawebpage/home/fundacion-mobile.jpg'
        title='RESPONSABILIDAD SOCIAL'
        subtitle1='Apoyamos a la niñez,'
        subtitle2='PORQUE CREEMOS POSIBLE UN FUTURO MEJOR'
        text='Como parte del compromiso que la empresa tiene con el País que le vio nacer, Terramar tiene el programa “Creando Sonrisas”, un proyecto a través del cual se construyen y rehabilitan aulas, bibliotecas, salones de cómputo, comedores y un sinfín de áreas en escuelas de zonas marginadas en México, para que los niños y niñas que asisten a estos planteles cuenten con espacios más dignos y adecuados para estudiar.'
        text2='Este programa funciona mediante la promoción y venta mensual de un producto, del que la totalidad de la utilidad se entrega como donativo, a través de una Asociación Civil, a instituciones públicas que carecen de recursos para mejorar sus instalaciones.'
        seeMore='Ver mas'
        textInBottom={true}
        rowReverse={true}
        backgroundColor='#f6efe6'
        buttonCallback={() => {
          window.scrollTo(0, 0);
          history.push('/creandoSonrisas');
        }}
      />

      <BlankSpace height={24} />

      {/* <Contador device={device} /> */}
      <Stripe device={device} />
    </div>
  );
};

export default Home;
