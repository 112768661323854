import DayItem from './DayItem';

import styles from './index.module.sass';

import calendarData from './calendarData';

import SANTA from './santa-advent.webp';

export default function AdventCalendar({ device }) {
  const { screenSize = 'desktop' } = device;
  return (
    <div
      className={`${styles.calendarContainer} ${styles[screenSize]}`}
      style={{
        backgroundImage: `url(${SANTA})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}>
      <div className={styles.adventContainer}>
        {calendarData.map((day) => {
          return <DayItem key={day.name} dayData={day} />;
        })}
      </div>
    </div>
  );
}
