const calendarData = [
  {
    name: 'Día 1',
    initDate: 'December 1, 2024 09:00:00',
    finishDate: 'December 1, 2024 23:59:59',
    imageActive: 'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-1.webp',
    imageInactive: '',
  },
  {
    name: 'Día 2',
    initDate: 'December 2, 2024 09:00:00',
    finishDate: 'December 2, 2024 23:59:59',
    imageActive: 'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-2.webp',
    imageInactive: '',
  },
  {
    name: 'Día 3',
    initDate: 'December 3, 2024 09:00:00',
    finishDate: 'December 3, 2024 23:59:59',
    imageActive: 'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-3.webp',
    imageInactive: '',
  },
  {
    name: 'Día 4',
    initDate: 'December 4, 2024 09:00:00',
    finishDate: 'December 4, 2024 23:59:59',
    imageActive: 'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-4.webp',
    imageInactive: '',
  },
  {
    name: 'Día 5',
    initDate: 'December 5, 2024 09:00:00',
    finishDate: 'December 5, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-5-6f61d427-4e33-4d9a-ac95-10ceb497b51a.webp',
    imageInactive: '',
  },
  {
    name: 'Día 6',
    initDate: 'December 6, 2024 09:00:00',
    finishDate: 'December 6, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-6-ae585150-f414-40b7-b03f-b423f8306759.webp',
    imageInactive: '',
  },
  {
    name: 'Día 7',
    initDate: 'December 7, 2024 09:00:00',
    finishDate: 'December 7, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-7-7478f390-5cd9-4738-83dd-9e2ce7c02e3e.webp',
    imageInactive: '',
  },
  {
    name: 'Día 8',
    initDate: 'December 8, 2024 09:00:00',
    finishDate: 'December 8, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-8-f35fea8c-1910-4341-9690-dea08a678207.webp',
    imageInactive: '',
  },
  {
    name: 'Día 9',
    initDate: 'December 9, 2024 09:00:00',
    finishDate: 'December 9, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-9-f279fc3f-9312-4f45-ac28-3a7ab627867d.webp',
    imageInactive: '',
  },
  {
    name: 'Día 10',
    initDate: 'December 10, 2024 09:00:00',
    finishDate: 'December 10, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-10-dcc5d655-2726-47a4-aa05-f3fc5515bedd.webp',
    imageInactive: '',
  },
  {
    name: 'Día 11',
    initDate: 'December 11, 2024 09:00:00',
    finishDate: 'December 11, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-11-c26614d8-1b28-4b10-bacd-11eb6172dbb3.webp',
    imageInactive: '',
  },
  {
    name: 'Día 12',
    initDate: 'December 12, 2024 09:00:00',
    finishDate: 'December 12, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-12-f5730a28-e20e-4629-b5b1-085b54eb3b26.webp',
    imageInactive: '',
  },
  {
    name: 'Día 13',
    initDate: 'December 13, 2024 09:00:00',
    finishDate: 'December 13, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-13-fb1422bc-51a9-4378-8d74-2b9ef0cf0c5d.webp',
    imageInactive: '',
  },
  {
    name: 'Día 14',
    initDate: 'December 14, 2024 09:00:00',
    finishDate: 'December 14, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-14-0c9ab8a0-fc37-41a5-a5af-8a46124882e2.webp',
    imageInactive: '',
  },
  {
    name: 'Día 15',
    initDate: 'December 15, 2024 09:00:00',
    finishDate: 'December 15, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-15-dd8b27d4-a555-4f18-afbc-eca1692a8119.webp',
    imageInactive: '',
  },
  {
    name: 'Día 16',
    initDate: 'December 16, 2024 09:00:00',
    finishDate: 'December 16, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-16-38878545-4ae5-4ffa-9e36-e4e1ccabc949.webp',
    imageInactive: '',
  },
  {
    name: 'Día 17',
    initDate: 'December 17, 2024 09:00:00',
    finishDate: 'December 17, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-17-5f8bac52-8565-4b42-a4d6-b7c517cd1b15.webp',
    imageInactive: '',
  },
  {
    name: 'Día 18',
    initDate: 'December 18, 2024 09:00:00',
    finishDate: 'December 18, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-18-a4688999-44b2-4089-9743-ee55c126218a.webp',
    imageInactive: '',
  },
  {
    name: 'Día 19',
    initDate: 'December 19, 2024 09:00:00',
    finishDate: 'December 19, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-19-ef8136ea-70b4-44a5-95fa-babbc25b27ba.webp',
    imageInactive: '',
  },
  {
    name: 'Día 20',
    initDate: 'December 20, 2024 09:00:00',
    finishDate: 'December 20, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-20-999828db-25ab-47d1-a742-2115e36bbf30.webp',
    imageInactive: '',
  },
  {
    name: 'Día 21',
    initDate: 'December 21, 2024 09:00:00',
    finishDate: 'December 21, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-21-57c4a28a-7a58-422f-8634-a9c613a13df2.webp',
    imageInactive: '',
  },
  {
    name: 'Día 22',
    initDate: 'December 22, 2024 09:00:00',
    finishDate: 'December 22, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-22-99035b24-5261-4522-afc1-859ef4bc4033.webp',
    imageInactive: '',
  },
  {
    name: 'Día 23',
    initDate: 'December 23, 2024 09:00:00',
    finishDate: 'December 23, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-23-3c54d242-45d4-4ee4-a7b5-0e712aef7ce8.webp',
    imageInactive: '',
  },
  {
    name: 'Día 24',
    initDate: 'December 24, 2024 09:00:00',
    finishDate: 'December 24, 2024 23:59:59',
    imageActive:
      'https://webimages.terramarbrands.com.mx/webpage/advent/promo-day-24-cdd94c6b-9633-4edc-8d98-e1514f69e39c.webp',
    imageInactive: '',
  },
];

export default calendarData;
