import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.sass';

//Commponents
import HeroBanner from '../HeroBanner';
import Stripe from '../Stripe';
import Title from '../Title';

const Career = (props) => {
  const { device = {} } = props;
  const { screenSize = 'desktop' } = device;

  const { t } = useTranslation('career');

  const block1 = t('block1.content').split('<br>');
  const block2 = t('block2.content').split('<br>');

  return (
    <div id={styles.Career} className={styles[screenSize]}>
      <HeroBanner device={device} desktopImg={t('bannerDesktop')} mobileImg={t('bannerMobile')} />
      <Title device={device} title={t('title')} subtitle={t('subtitle')} />
      <Stripe device={device} />
      <div className={styles.block}>
        <div className={styles.text}>
          <div className={styles.textContainer}>
            <div className={styles.blockTitle}>{t('block1.title')}</div>
            {block1.map((val, key) => {
              return (
                <div className={styles.paragraph} key={key}>
                  {val}
                </div>
              );
            })}
          </div>
        </div>
        {screenSize !== 'phone' && (
          <div className={styles.imageContainer}>
            <img src={t('block1.image')} alt='' />
          </div>
        )}
      </div>

      <div className={`${styles.block} ${styles.last}`}>
        <div className={styles.text}>
          <div className={styles.textContainer}>
            <div className={styles.blockTitle}>{t('block2.title')}</div>
            {block2.map((val, key) => {
              return (
                <div className={styles.paragraph} key={key}>
                  {val}
                </div>
              );
            })}
          </div>
          <div>
            <a
              className={styles.button}
              // href='https://webimages.terramarbrands.com.mx/documents/career/PROGRAMA_LINAJE_TERRAMAR.pdf'
              href='https://webimages.terramarbrands.com.mx/documents/career/PROGRAMA_LINAJE_TERRAMAR_NVO.pdf'
              target='_blank'
              rel='noopener noreferrer'>
              {t('titleButton')}
            </a>
          </div>
        </div>
        {screenSize !== 'phone' && (
          <div className={styles.imageContainer}>
            <img src={t('block2.image')} alt='' />
          </div>
        )}
      </div>
    </div>
  );
};

export default Career;
